import React from 'react'

const BannerButtons = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opacity: 0,
        color: 'lightgrey'
    }
  }


  componentDidMount() {
    this.setState({
      opacity: 0
    });
    setTimeout(() => {
      this.setState({opacity: 1});
        this.setState({color: 'white'});

    }, 1000)

  }


  render() {
    return (

        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <a href="http://eepurl.com/gIbELT" target="_blank" className="banner-button-container sign-up-btn" style={{flex: 0.5, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span className="banner-button">SIGN UP</span>
            </a>
            <a href="https://secure.actblue.com/donate/one-texas" target="_blank" className="banner-button-container contribute-btn" style={{flex: 0.5, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span className="banner-button">DONATE</span>
            </a>
  </div>
    )
  }
};

export default BannerButtons
