import React from 'react'
import { Link } from 'gatsby'
import logo from '../../static/img/Logo white_large text.svg'
import facebookIcon from '../../static/img/fb-icon.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
        fbIconFill: '#0099CC',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  handleOnMouseEnter = () => {
      this.setState(
          {
              fbIconFill: 'white'
          }
      )
  }

  handleOnMouseExit = () => {
      this.setState(
          {
              fbIconFill: '#0099CC'
          }
      )
  }


  componentDidMount() {
    window.onscroll = function() {makeNavbarSticky()};

     const makeNavbarSticky = () => {
          let navbar = document.getElementById('nav');

          let sticky = navbar.offsetTop;

          if (window.pageYOffset > sticky) {
              navbar.classList.add("sticky");
              // console.log(navbar);
          } else {
              navbar.classList.remove("sticky");
          }
      }



  }



  render() {
    return (
          <nav
            className="navbar is-transparent"
            role="navigation"
            aria-label="main-navigation"
            id="nav"
          >
            <div className="container">
              <div className="navbar-brand navbar-logo" >
                <Link to="/" className="navbar-item navbar-logo" title="One Texas" id="logo">
                  <img src={logo} alt="One Texas" style={{ width: '120px' }} />
                </Link>
                {/* Hamburger menu */}
                <div
                  className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                  data-target="navMenu"
                  onClick={() => this.toggleHamburger()}
                >
                  <span style={{color: 'white'}} />
                  <span style={{color: 'white'}}/>
                  <span style={{color: 'white'}}/>
                </div>
              </div>
              <div
                id="navMenu"
                className={`navbar-menu ${this.state.navBarActiveClass}`}
              >
                <div id="navbar-items" className="navbar-start has-text-centered" >

                  <Link className="navbar-item" onClick={(e) => {this.props.linkName(e)}} to="#slider">
                    ONE VISION
                  </Link>

                  <Link className="navbar-item" onClick={(e) => {this.props.linkName(e)}} to="#slider">
                    ONE PLAN
                  </Link>


                    <Link className="navbar-item" onClick={(e) => {this.props.linkName(e)}} to="#slider">
                        ONE TEAM
                    </Link>

                    <a className="navbar-item social-navbar-icon" style={{padding: 0, margin: 'auto',}} href="https://facebook.com/OneTexasPAC" target="_blank">
                        <svg fill={this.state.fbIconFill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1.35em" height="1.35em">
                            <path d="M 19.253906 2 C 15.311906 2 13 4.0821719 13 8.8261719 L 13 13 L 8 13 L 8 18 L 13 18 L 13 30 L 18 30 L 18 18 L 22 18 L 23 13 L 18 13 L 18 9.671875 C 18 7.884875 18.582766 7 20.259766 7 L 23 7 L 23 2.2050781 C 22.526 2.1410781 21.144906 2 19.253906 2 z"/>
                        </svg>
                    </a>
                    <a className="navbar-item social-navbar-icon" style={{margin: 'auto',}}  href="https://twitter.com/OneTexasPAC" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="1.25em" height="1.25em" fill={this.state.fbIconFill}>
                            <path
                                d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z"/>
                        </svg>
                    </a>


                </div>
                <div className="navbar-end has-text-centered">
                    <a className="take-action-btn-anchor" href="https://secure.actblue.com/donate/one-texas" target="_blank">
                      <button type="button" className="take-action-btn">
                        TAKE ACTION
                      </button>
                    </a>
                </div>
              </div>
            </div>
          </nav>
    )
  }
}

export default Navbar
