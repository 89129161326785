import React from 'react'
import { Link } from 'gatsby'

import logo from '../../static/img/Logo grey_large text.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

import BannerButtons from '../components/BannerButtons'

const Footer = class extends React.Component {
  render() {
    return (
      <footer id="footer" className="footer has-background-black has-text-white-ter" style={{padding: '0'}}>

        <div className="content has-text-centered has-background-black has-text-white-ter">
          <BannerButtons/>

          <div className="container has-background-black has-text-white-ter">

            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center', padding:'10px', alignSelf: 'center', justifySelf: 'center'}}>

              <div className="footer-logo" style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
                <img
                    src={logo}
                    alt="One Texas"
                    style={{ height: '3vmin', minHeight: '20px', alignSelf: 'center', justifySelf: 'center', display: 'flex', padding: 0, margin: 0}}
                />

                <div className="footer-contact-link-alt" style={{textAlign: 'center'}}>
                  <Link to="/contact">CONTACT</Link>
                </div>
              </div>
              <div className="footer-contact-link" style={{textAlign: 'center'}}>
                <Link to="/contact">CONTACT</Link>
              </div>
              <div className="footer-political-disclaimer">
                <p className="paid-political-ad">Pol. Ad. Paid for by One Texas PAC and not authorized by any candidate or candidate’s committee.</p>
              </div>



              <div className="social" style={{flex: 0.8, display: 'none'}}>
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
