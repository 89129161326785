import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const handleNavbarLinkClick = (e) => {
  // this.props.linkName = e;
  // console.log(e.target);
  const linkMap = {
    "vision": "ONE VISION",
    "plan": "ONE PLAN",
    "team": "ONE TEAM"
  };
  const innerHTML = e.target.innerHTML;
  // console.log(innerHTML);
  const elementId = Object.keys(linkMap).find(key => linkMap[key] === innerHTML);
  // console.log(elementId);
  let el = document.getElementById('link-name-placeholder');
  el.value = elementId;
  el.innerHTML = elementId;

  // console.log(elementId + " handled");
  var event = new Event('onchange');
  document.cookie = `link-name=${elementId}`;
  el.dispatchEvent(event);

};

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/icon.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/icon.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />

        <link rel="stylesheet" href="https://use.typekit.net/aum8sne.css" />



        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/Logo.png`}
        />
      </Helmet>

      <Navbar linkName={function(e) {handleNavbarLinkClick(e)}} />
      <div>{children}</div>
      <Footer/>
    </div>
  )
}

export default TemplateWrapper
